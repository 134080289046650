<template>
  <div>
    <NutritionPieChart :chartData="chartData"></NutritionPieChart>
    <NutritionLegend :legends="legends" class="mt-3"></NutritionLegend>
  </div>
</template>

<script>
import { chartColors } from '@/core/setting';

export default {
  name: 'NutritionDonut',
  components: {
    NutritionPieChart: () => import('@/components/nutrition/NutritionPieChart'),
    NutritionLegend: () => import('@/components/nutrition/NutritionLegend'),
  },
  props: {
    macronutrients: {
      type: Object,
      required: true,
    },
  },
  computed: {
    carb() {
      return this.macronutrients.c.value;
    },
    carbCals() {
      return this.carb * 4;
    },
    carbPerc() {
      return (this.carbCals / this.calories) * 100;
    },
    protein() {
      return this.macronutrients.p.value;
    },
    proteinCals() {
      return this.protein * 4;
    },
    proteinPerc() {
      return (this.proteinCals / this.calories) * 100;
    },
    fat() {
      return this.macronutrients.f.value;
    },
    fatCals() {
      return this.fat * 9;
    },
    fatPerc() {
      return (this.fatCals / this.calories) * 100;
    },
    calories() {
      return this.macronutrients.total.value;
    },
    chartData() {
      const percC = Math.round(this.carbPerc);
      const percP = Math.round(this.proteinPerc);
      const percF = Math.round(this.fatPerc);
      return {
        labels: [
          'Carb',
          'Protein',
          'Fat',
        ],
        datasets: [
          {
            data: [percC, percP, percF],
            backgroundColor: [
              chartColors.carb,
              chartColors.protein,
              chartColors.fat,
            ],
          },
        ],
      };
    },
    legends() {
      return [
        {
          label: `Carb: ${Math.round(this.carbPerc)}%`,
          color: chartColors.carb,
        },
        {
          label: `Protein: ${Math.round(this.proteinPerc)}%`,
          color: chartColors.protein,
        },
        {
          label: `Fat: ${Math.round(this.fatPerc)}%`,
          color: chartColors.fat,
        },
      ];
    },
  },
  data() {
    return {
      colors: chartColors,
    };
  },
};
</script>
